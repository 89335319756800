import * as React from "react"
import { Text, Heading, Image, HStack, VStack, Box, Center, Spacer, Flex, TableCaption, TableContainer, Table, Td, Tr, Button, Drawer, DrawerContent, DrawerOverlay, useDisclosure, Radio, RadioGroup, Stack } from "@chakra-ui/react"
import LayoutFrame from "../components/layout_frame"
import { DataName, TimeTableData, TimeTableRow } from "../data/time_line_data"
import { accent_color, main_color } from "../components/UItheme"
import { totalmem } from "os"
import { EventCard } from "../components/evnet_card"
import { selectEventByName } from "../data/event_selector"

import ad_divide4_1 from "../images/ad_images/divide4/4_1.jpg"
import ad_divide4_2 from "../images/ad_images/divide4/4_2.jpg"
import ad_divide4_3 from "../images/ad_images/divide4/4_3.jpg"
import ad_divide4_4 from "../images/ad_images/divide4/4_4.jpg"
import ad_divide4_5 from "../images/ad_images/divide4/4_5.jpg"
import ad_divide4_6 from "../images/ad_images/divide4/4_6.jpg"
import ad_divide4_7 from "../images/ad_images/divide4/4_7.jpg"
import ad_divide4_8 from "../images/ad_images/divide4/4_8.jpg"
import ad_divide4_9 from "../images/ad_images/divide4/4_9.jpg"
import ad_divide4_10 from "../images/ad_images/divide4/4_10.jpg"
import ad_divide4_11 from "../images/ad_images/divide4/4_11.jpg"
import ad_divide4_12 from "../images/ad_images/divide4/4_12.jpg"
import ad_divide4_13 from "../images/ad_images/divide4/4_13.jpg"
import ad_divide4_14 from "../images/ad_images/divide4/4_14.jpg"
import ad_divide4_15 from "../images/ad_images/divide4/4_15.jpg"


const TimeTable = () => {
    const hours = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    const height_per_one_hour = 100
    const header_height = 50
    const data_class = new TimeTableData

    const column_width = 200
    let column_height = hours.length * height_per_one_hour

    /**
    まず縦に分ける
    前のイベントとの空き時間をmarginTopの大きさに指定。それ自体の長さをheightにして積み上げてるだけ
    その日の開始時刻からの経過をエクセル的なやつで分単位にして前後との差分を使う。
    表示には分単位の数値を60分あたりの表示長さに変換するのに注意する
     */

    const [day, changeDay] = React.useState('1')


    const ad_divide4 = () => {
        if (day == '1') {
            return (
                <Box>
                    <Image marginTop={3} src={ad_divide4_1} />
                    <Image marginTop={3} src={ad_divide4_2} />
                    <Image marginTop={3} src={ad_divide4_3} />
                    <Image marginTop={3} src={ad_divide4_4} />
                    <Image marginTop={3} src={ad_divide4_5} />
                </Box>
            )
        } else if (day == '2') {
            return (
                <Box>
                    <Image marginTop={3} src={ad_divide4_6} />
                    <Image marginTop={3} src={ad_divide4_7} />
                    <Image marginTop={3} src={ad_divide4_8} />
                    <Image marginTop={3} src={ad_divide4_9} />
                    <Image marginTop={3} src={ad_divide4_10} />
                </Box>
            )
        } else if (day == '3') {
            return (
                <Box>
                    <Image marginTop={3} src={ad_divide4_11} />
                    <Image marginTop={3} src={ad_divide4_12} />
                    <Image marginTop={3} src={ad_divide4_13} />
                    <Image marginTop={3} src={ad_divide4_14} />
                    <Image marginTop={3} src={ad_divide4_15} />
                </Box>
            )
        }
    }

    return (
        <LayoutFrame>
            <Text>日付を選ぶ</Text>
            <RadioGroup onChange={changeDay} value={day}>
                <Stack direction='row'>
                    <Radio value='1'>25(金)day1</Radio>
                    <Radio value='2'>26(土)day2</Radio>
                    <Radio value='3'>27(日)day3</Radio>
                </Stack>
            </RadioGroup>

            <TableContainer borderTop="1px" borderColor={main_color}>
                <Table height={hours.length * height_per_one_hour + header_height}>
                    <Tr>
                        <Td padding={1}>
                            <VStack spacing={0}>
                                <Text>時間</Text>
                                {
                                    hours.map((h) =>
                                        <Box
                                            height={height_per_one_hour}
                                            borderTop="1px"
                                            marginTop="0px"

                                        >{h + ":00"}
                                        </Box>
                                    )
                                }
                            </VStack>
                        </Td>
                        <Td padding={1}>
                            <TimeColumn title="サル山"
                                list={data_class.getList(day, "saruyama")}
                                height_per_one_hour={height_per_one_hour}
                                column_height={column_height}
                                column_width={column_width} />
                        </Td>
                        <Td padding={1}>
                            <TimeColumn title="第二食堂"
                                list={data_class.getList(day, "nisyoku")}
                                height_per_one_hour={height_per_one_hour}
                                column_height={column_height}
                                column_width={column_width} />
                        </Td>
                        <Td padding={1}>
                            <TimeColumn title="大学会館"
                                list={data_class.getList(day, "kaikann")}
                                height_per_one_hour={height_per_one_hour}
                                column_height={column_height}
                                column_width={column_width} />
                        </Td>
                        <Td padding={1}>
                            <VStack spacing={0}>
                                <Text>時間</Text>
                                {
                                    hours.map((h) =>
                                        <Box
                                            height={height_per_one_hour}
                                            borderTop="1px"
                                            marginTop="0px"

                                        >{h + ":00"}
                                        </Box>
                                    )
                                }
                            </VStack>
                        </Td>
                    </Tr>
                </Table>
            </TableContainer>

            {
                ad_divide4()
            }




        </LayoutFrame>
    )
}
export default TimeTable

const TimeColumn = ({ title, list, height_per_one_hour, column_height, column_width }: { title: string, list: TimeTableRow[], height_per_one_hour: number, column_height: number, column_width: number }) => {
    let space_left = column_height

    //on click
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [clickedEventName, setClickedEventName] = React.useState("")
    const onEventClick = (event_name: string) => {
        setClickedEventName(event_name)
        onOpen()
    }
    return (
        <VStack spacing={0} width={column_width}>
            <Text>{title}</Text>
            {
                list.map((event) => {
                    //minなのでこれをhourにし、一時間あたりの高さで調整する
                    const height = (event.this_event_time_length / 60) * height_per_one_hour
                    const interval_space = (event.margin_top / 60) * height_per_one_hour

                    space_left -= (height + interval_space)

                    if (height > 50) {
                        let noOfLines = 1
                        if (height < 80) {
                            noOfLines = 1
                        } else if (height < 100) {
                            noOfLines = 2
                        }
                        return (
                            <Box width="100%" onClick={() => onEventClick(event.event_name)}>
                                {/**margin使用不能 */}
                                <Box height={interval_space}></Box>
                                <VStack
                                    height={height}

                                    align="start"
                                    display="flex"

                                    borderColor={accent_color}
                                    borderWidth="1.5px"
                                    borderRadius="md">
                                    <Text fontSize="sm">{event.start_h + ":" + event.start_m + "開始" + "-" + event.end_h + ":" + event.end_m + "終了"}</Text>
                                    <Text whiteSpace="normal" noOfLines={noOfLines}>{event.event_name}</Text>
                                </VStack>
                            </Box>
                        )
                    } else {
                        return (
                            <Box width="100%" onClick={() => onEventClick(event.event_name)}>
                                {/**margin使用不能 */}
                                <Box height={interval_space}></Box>
                                <HStack
                                    height={height}

                                    align="start"
                                    display="flex"

                                    borderColor={accent_color}
                                    borderWidth="1.5px"
                                    borderRadius="md">
                                    <Text fontSize="sm" overflowX="hidden">{event.start_h + ":" + event.start_m + "-" + event.end_h + ":" + event.end_m + " " + event.event_name}</Text>

                                </HStack>
                            </Box>
                        )
                    }

                })
            }
            {/**何やっても効かないからこれ。全体の高さは適切に制限すること */}
            <Box height={space_left}></Box>

            <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay>
                    <DrawerContent>
                        <Box margin="0 auto" paddingTop={3} paddingBottom={3} maxWidth="xl">
                            <HStack marginBottom={3}>
                                <Heading as="h2">イベント詳細</Heading>
                                <Spacer />
                                <a href="/events">
                                    <Button backgroundColor={accent_color}>イベント一覧へ</Button>

                                </a>

                            </HStack>

                            <Box maxWidth="md" margin="0 auto">
                                <EventCard event={selectEventByName(clickedEventName)} />

                            </Box>

                        </Box>

                    </DrawerContent>
                </DrawerOverlay>

            </Drawer>
        </VStack>
    )
}