import { Heading, HStack } from "@chakra-ui/react"
import React from "react"
import LayoutFrame from "../../components/layout_frame"
import ShowMap from "../../components/show_map"
import { filterEventsByPlace, getAllEvents } from "../../data/event_selector"
import { Place } from "../../data/places"
import { Event } from "../../data/event_types"
import { EventList } from "../../components/event_list"
import { Image } from "@chakra-ui/react"
import c from "../../images/map/c.png"

import ad_divide12_3 from "../../images/ad_images/divide12/12_3.jpg"
import ad_divide12_4 from "../../images/ad_images/divide12/12_4.jpg"

const Zengaku1 = () => {
    const place: Place = "エリアC"
    const events_here: Event[] = filterEventsByPlace(getAllEvents(),place)
    return(
        <LayoutFrame>
            <Heading>{place}</Heading>
            <Image src={c}/>

            <HStack>
                <Image src={ad_divide12_3} width="50%" />
                <Image src={ad_divide12_4} width="50%" />
            </HStack>

            <Heading paddingTop={5}>{place + "で行われるイベント一覧"}</Heading>
            <EventList events={events_here} />
        </LayoutFrame>
    )
}
export default Zengaku1