import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import LayoutFrame from "../../components/layout_frame"

//@きもとえいすけ

import Suisougaku from "../../images/suisougaku.jpg"

const SuisougakuCollab = () => {
    return (
        <LayoutFrame>
            <Heading marginTop={5}>吹奏楽部コラボレーションコンサート</Heading>
            <Image src={Suisougaku} />
            <Text>
                埼玉大学と上大久保中学校の吹奏楽部コラボコンサートです。<br/>
                <br/>
                例年開催されていた人気企画が今年も開催！<br/>
                <br/>
                大学生と中学生の世代を超えた吹奏楽の音色が大学会館に響き渡りますのでお楽しみに。<br/>
                <br/>
                入場無料でどなたでもご覧になれますので、お気軽にお立ち寄りください。たくさんの方のご来場をお待ちしております！
            </Text>
            <Text marginTop={5}>
                日にち 11/26(土) むつめ祭2日目<br/>
                時間 15:20開場 15:45分開始 (予定)<br/>
                場所 大学会館3階大集会室<br/>
                団体 埼玉大学吹奏楽部＆上大久保中学校吹奏楽部
            </Text>
        </LayoutFrame>
    )
}
export default SuisougakuCollab

