import React from "react";
import { EventCard } from "./evnet_card";
import { EventType } from "../data/event_types";
import { Box, Button, Center, Container, Drawer, DrawerContent, DrawerOverlay, Flex, Heading, HStack, Image, Link, Spacer, Table, TableContainer, Tbody, Td, Text, Tr, useDisclosure, VStack } from "@chakra-ui/react";
import { Event } from "../data/event_types";
import { ArrowRightIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { accent_color, main_color } from "./UItheme";
import { selectEventByName } from "../data/event_selector";

//import EventしないとUIイベント系と名前が被ってるらしくてエラーになる
const EventListHorizontal = ({ events }: { events: Event[] }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [clickedEventName, setClickedEventName] = React.useState("")
    const onEventClick = (event_name: string) => {
        setClickedEventName(event_name)
        onOpen()
    }

    //chakra ui の tableを使う。
    //table内ではwhiteSpace="nowrap"が外から適用されるのでnormalに戻す

    return (
        <Box>
            <TableContainer>
                <Table>
                    <Tbody>
                        <Tr>
                            {events.map(
                                (event) => {

                                    {
                                        //デフォルトで広すぎるpaddingをつけられるので上書き
                                    }
                                    return (

                                        <Td padding={1}>
                                            <Box onClick={() => onEventClick(event.event_name)} borderWidth="2px" borderRadius="lg" width="150px" height={250}>
                                                <Image
                                                    src={`/event_images/${event.id}.png`}
                                                    height={150}
                                                    width="100%"
                                                    objectFit="cover"
                                                />
                                                <Heading as="h2" fontSize={20} whiteSpace="nowrap" overflow="hidden">{event.event_name}</Heading>
                                                {
                                                    //table内ではwhiteSpace="nowrap"が外から適用されるらしい。だるい。
                                                }
                                                <Text whiteSpace="normal" noOfLines={3}>{"　" + event.introduction}</Text>
                                            </Box>
                                        </Td>


                                    )
                                }
                            )}

                            <Td padding={1}>
                                <Link href="/events">
                                    <Flex
                                        borderWidth="2px"
                                        borderRadius="lg"
                                        width="100px"
                                        height={250}
                                        justifyContent="center"
                                        alignItems="center"

                                    >
                                        <ArrowRightIcon w={6} h={6} color={accent_color} />


                                    </Flex>
                                </Link>
                            </Td>
                        </Tr>

                    </Tbody>
                </Table>
            </TableContainer>




            <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay>
                    <DrawerContent>
                        <Box margin="0 auto" paddingTop={3} paddingBottom={3} maxWidth="xl">
                            <HStack marginBottom={3}>
                                <Heading as="h2">イベント詳細</Heading>
                                <Spacer />
                                <a href="/events">
                                    <Button backgroundColor={accent_color}>イベント一覧へ</Button>

                                </a>

                            </HStack>

                            <Box maxWidth="md" margin="0 auto">
                                <EventCard event={selectEventByName(clickedEventName)} />

                            </Box>

                        </Box>

                    </DrawerContent>
                </DrawerOverlay>

            </Drawer>
        </Box>
    )
}
export default EventListHorizontal