import * as React from "react"
import { Text, Heading, Image} from "@chakra-ui/react"
import LayoutFrame from "../components/layout_frame"
import OfficialEventList from "../components/official/official_event_list"
import EventListHorizontal from "../components/event_list_horizontal"

import TamamiImage from "../images/character_tamami.png"

import ad_divide2_1 from "../images/ad_images/divide2/2_1.jpg"
import ad_divide2_2 from "../images/ad_images/divide2/2_2.jpg"
import ad_divide2_3 from "../images/ad_images/divide2/2_3.jpg"

const OfficalEventGuide = () => {
    return(
        <LayoutFrame>
            <Heading>公式企画一覧</Heading>
            <OfficialEventList />

            <Image src={TamamiImage} width="50%"/>
            <Text>むつめ祭キャラクター「たまみ」</Text>

            <Image src={ad_divide2_1} width="90%"/>
            <Image src={ad_divide2_2} width="90%"/>
            <Image src={ad_divide2_3} width="90%"/>
        </LayoutFrame>
    )
}
export default OfficalEventGuide