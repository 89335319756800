import { Heading, HStack, Image } from "@chakra-ui/react"
import React from "react"
import LayoutFrame from "../../components/layout_frame"
import ShowMap from "../../components/show_map"
import { filterEventsByPlace, getAllEvents } from "../../data/event_selector"
import { Place } from "../../data/places"
import { Event } from "../../data/event_types"
import { EventList } from "../../components/event_list"

import zengaku1 from "../../images/map/zengaku1.png"

import ad_divide12_19 from "../../images/ad_images/divide12/12_19.jpg"
import ad_divide12_20 from "../../images/ad_images/divide12/12_20.jpg"

const Zengaku1 = () => {
    const place: Place = "全学講義棟1号館"
    const events_here: Event[] = filterEventsByPlace(getAllEvents(),place)
    return(
        <LayoutFrame>
            <ShowMap place={place}/>
            <Image src={zengaku1} />

            <HStack>
                <Image src={ad_divide12_19} width="50%" />
                <Image src={ad_divide12_20} width="50%" />
            </HStack>
            

            <Heading paddingTop={5}>{place + "で行われるイベント一覧"}</Heading>
            <EventList events={events_here} />
        </LayoutFrame>
    )
}
export default Zengaku1