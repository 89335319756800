import { Heading } from "@chakra-ui/react"
import React from "react"
import LayoutFrame from "../../components/layout_frame"
import ShowMap from "../../components/show_map"
import { filterEventsByPlace, getAllEvents } from "../../data/event_selector"
import { Place } from "../../data/places"
import { Event } from "../../data/event_types"
import { EventList } from "../../components/event_list"

const Zengaku1 = () => {
    const place: Place = "弓道場"
    const events_here: Event[] = filterEventsByPlace(getAllEvents(),place)
    return(
        <LayoutFrame>
            <ShowMap place={place}/>

            <Heading>{place + "で行われるイベント一覧"}</Heading>
            <EventList events={events_here} />
        </LayoutFrame>
    )
}
export default Zengaku1