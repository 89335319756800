import * as React from "react"
import { Text, Heading, Image, Center, VStack } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"
import LayoutFrame from "../../components/layout_frame"

const PrivacyPolicy = () => {
    return (
        <LayoutFrame>
            <Center>
                <Heading margin={15}>プライバシーポリシー</Heading>
            </Center>

            <VStack align="left">
                <Text marginTop={5}>　埼⽟⼤学第 73 回むつめ祭常任委員会（以下当委員会）は、埼⽟⼤学でのむつめ祭を開催、
                    運営するにあたって、委員会の提供するサービスをご利⽤になる⽅から提供していただい
                    た個⼈情報に関し、以下のような取り組みを実施いたします。</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>１．個⼈情報</Heading>
                <Text>　当委員会に提出されました、協賛に関する情報、催し物の参加に関する情報及び来
                    客されました⽅々の情報などのすべての個⼈情報に対し、当委員会は個⼈情報に関す
                    る法令を遵守し、個⼈情報の適正な取り扱いを⾏うなどの個⼈情報の保護に努めます。</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>２．個⼈情報の収集、利⽤の⽬的</Heading>
                <Text>　当委員会は下記の⽬的でのみ個⼈情報を使⽤します。<br />
                    ・当委員会がむつめ祭を開催、運営、及び企画の実⾏をするため<br />
                    ・参加登録、各種申請に関する業務のため<br />
                    ・各種お問い合わせに対する回答のため<br />
                    ・むつめ祭に関する連絡、通知や案内のため</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>3． 個⼈情報の第三者への提供</Heading>
                <Text>　当委員会は提供していただきました個⼈情報を適切に管理し、原則第三者に⼀切提
                    供いたしません。しかし以下のいずれかに該当する場合は、当委員会が必要と判断した
                    うえで提供いたします。<br />
                    ・本⼈の同意がある場合<br />
                    ・法令に基づき提供する場合<br />
                    ・埼⽟⼤学の諸機関や国の機関もしくは地⽅⾃治体及びその委託を受
                    けた者に対して提供の義務が⽣じた場合<br />
                    なお，次期以降のむつめ祭常任委員会に，取得した個⼈情報を提供する場合がありま
                    す。この場合，最新のプライバシーポリシーを遵守致します。</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>４. 個⼈情報の開⽰、訂正および削除などの請求</Heading>
                <Text>　当委員会は提供していただきました個⼈情報の開⽰、訂正および削除などの請求を
                    求められた場合、第三者の権利利益を害さないように、法令に基づき速やかにご本⼈に
                    対し個⼈情報を開⽰、訂正および削除などの⼿続きを⾏います。
                </Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>５．プライバシーポリシーの変更</Heading>
                <Text>　当委員会が定めたプライバシーポリシーの内容は、法令などの変更や必要に応じて
                    改定する場合があります。その際は最新のプライバシーポリシーを当委員会の公式
                    Web サイトで公開いたします。またプライバシーポリシーは当委員会が別途定める場
                    合を除き、公式 Web サイトに公開された時点で効⼒を⽣じるものといたします。</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>６．当委員会が扱う web サイト</Heading>
                <Text>　公式 Web サイトでは Cookie を利⽤したウェブサイトなどを通じて IP アドレス等の
                    情報の⼀部が、Google.Inc に収集される可能性がありますが、収集したデータが使⽤さ
                    れることはありません。</Text>

                <Heading as="h3" fontSize={20} paddingTop={5}>７．お問い合わせ</Heading>
                <Text>　本ポリシーに関するお問い合わせは下記のアドレスまでお願いいたします。<br />
                    メールアドレス staff アット mutsume.com<br />
                    （「@」を「アット」に変えております。ご注意ください。</Text>
            </VStack>
        </LayoutFrame>
    )
}
export default PrivacyPolicy