import { Heading, Text, Image, Spacer } from "@chakra-ui/react";
import * as React from "react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame";


import illumination from "../../images/official_event/illumination.jpg"
// 
const Illumination = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>むつめ祭イルミネーションプロジェクト2022</Heading>

            <Text>　むつめ祭では，「平和と幸福」をテーマにイルミネーションを点灯いたします。<br/>
                　「平和と幸福」の象徴の色と言われる青を中心に彩りよく装飾しました。
                是非，足をお運びください！お待ちしております。
                また，25日の16:40から「点灯式」を執り行いますので，こちらも是非ご参加ください。
            </Text>
            <Text>
                開催日：全日開催<br/>
                開催時間：17:00〜20:00（25日のみ点灯式にて点灯）<br/>
                開催場所：イルミネーションエリア（全学講義棟1号館と理学部講義実験棟の間の広場）</Text>

            
            <Image paddingTop={5} margin="0 auto" width="90%" src={illumination} />



        </OfficialEventLayoutFrame>
    )
}

export default Illumination