import { Heading, HStack, Image } from "@chakra-ui/react"
import React from "react"
import LayoutFrame from "../../components/layout_frame"
import ShowMap from "../../components/show_map"
import { filterEventsByPlace, getAllEvents } from "../../data/event_selector"
import { Place } from "../../data/places"
import { Event } from "../../data/event_types"
import { EventList } from "../../components/event_list"

import kyouyou from "../../images/map/kyouyou.png"

import ad_divide12_13 from "../../images/ad_images/divide12/12_13.jpg"
import ad_divide12_14 from "../../images/ad_images/divide12/12_14.jpg"

const Zengaku1 = () => {
    const place: Place = "教養学部棟"
    const events_here: Event[] = filterEventsByPlace(getAllEvents(),place)
    return(
        <LayoutFrame>
            <ShowMap place={place}/>
            <Image src={kyouyou} />

            <HStack>
                <Image src={ad_divide12_13} width="50%" />
                <Image src={ad_divide12_14} width="50%" />
            </HStack>


            <Heading paddingTop={5}>{place + "で行われるイベント一覧"}</Heading>
            <EventList events={events_here} />
        </LayoutFrame>
    )
}
export default Zengaku1