import * as React from "react"
import { Text, Heading, Image, Link } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"
import LayoutFrame from "../../components/layout_frame"

const CovidGuide = () => {
    return (
        <LayoutFrame>
            <Heading>第73回むつめ祭におけるコロナ対策について</Heading>
            <Text>
                【必ずご一読ください】<br />
                コロナ対策課より、全ての来場者様に第73回むつめ祭を安全に楽しんでもらうために、ご協力いただきたい内容を記載いたしました。ご一読の上、ご来場くださいますようお願いいたします。<br />
                <br />
                ・むつめ祭に来場される方には「マスクの着用」「定期的な手指の消毒」「不必要な会話の抑制」「三密の回避」の実施をお願いしております。具体的な対策内容は下のガイドラインをご覧ください。<br />
                <br />
                ・マスク着用は入場時に必須となっております。所持していない場合はこちらでお渡しいたします。<br />
                <br />
                ・場内での密集はお避けください。<br /><br />
                ・飲食時は黙食で、会話する際はマスクの着用をお願いします。食事の位置が指定されている場合は、その他の場所での食事はしないようお願いします。<br /><br />
                ・現状こちらでタオルハンカチ・ハンドペーパー・ティッシュ等のご用意はありません。各自で用意をお願いいたします。<br /><br />
                ・来場する際はその日の起床時に検温を実施し、37.5度以上を記録した場合やご自身で体調の悪さを感じた場合は来場をおやめください。その際、こちらへの連絡は不要です。<br /><br />
                ・入場口でも検温を行なっております。37.5度を超える場合や明らかに体調が悪いと判断した場合はお帰りいただく場合がございます。ご了承ください。<br /><br />
                ・当学祭をお楽しみいただいたあと、新型コロナウイルスへの感染が発覚した場合は速やかに(原則 2週間以内)当委員会へ連絡をお願いいたします。<br /><br />
                ・当委員会に学祭関係者及び来場者から陽性の報告があった場合はチケット購入の際にいただいた連絡先を用いて情報を共有させていただきます。<br /><br />
                ・構内で体調不良者がいた場合は委員までご連絡ください。対応いたします。<br /><br />
                ・本学祭はYahoo! JAPAN が運営するデジタルチケット販売サービス「Pass Market」を利用して入場者の管理を行なっています。<br /><br />
                ・チケット購入時に入力いただいた内容は行政からの要請やコロナウイルス陽性者が出た場合に使用します。<br /><br />
                ・再三のお願いや注意にも関わらず、当委員会の定めたガイドラインに応じていただけない場合は、お帰りいただく場合がございます。<br /><br />
                ・ガイドラインは周辺道路上での対応も含まれております。ご協力をお願いいたします。<br /><br />
                ・この文章はガイドラインの中で来場者に関わる部分を抜粋し、表現を変更して表示しています。ガイドライン全文は下のPDFをご覧ください。<br /><br />
                ・こちらに載っている文章は、感染状況や行政からの要請によるガイドラインの変更に伴い修正される場合があります。<br /><br />
                <br />

            </Text>
            <Link href="/covid_guide.pdf">
                <Text as="u">第73回むつめ祭におけるコロナ対策について[PDF]</Text>
            </Link>

        </LayoutFrame>
    )
}
export default CovidGuide