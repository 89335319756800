import React from "react";
import { EventCard } from "./evnet_card";
import { EventType } from "../data/event_types";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { Event } from "../data/event_types";


//import EventしないとUIイベント系と名前が被ってるらしくてエラーになる
export const EventList = ({events}:{events: Event[]}) => {
    return (
        <Box>
            <SimpleGrid columns={{sm: 2, md:2, lg:3}} spacing={3}>
                {events.map(
                    (e) => {

                        //eをe:Eventにすると型エラーが出て解消しないので仕方なくこのクソ冗長な書き方をする。tsもうやりたくない
                        //関数にすることも試みたが無理なのでこれを各所でコピペすること。保守性はありません。
                        return (<EventCard event={e}/>)
                    }
                )}
            </SimpleGrid>
        </Box>

    )
}