import * as React from "react"
import LayoutFrame from "../components/layout_frame"
import { Image, Link, Text, Heading, HStack, VStack } from "@chakra-ui/react"
import { Search2Icon, TimeIcon } from "@chakra-ui/icons"
import { main_color } from "../components/UItheme"


import mutsume_chan from "../images/character_mutsumechan.png"
import official_icon from "../images/favicon.png"
import ticket_icon from "../images/icon_ticket.svg"
import place_icon from "../images/icon_place.svg"

const NotFoundPage = () => {
  return (
    <LayoutFrame>
      <Heading>404 not fond</Heading>
      <Text>ご指定のページは見つかりませんでした</Text>

      <Link href="/">
        <Text fontSize={30}>トップページへ</Text>
      </Link>

      <VStack align="left" margin="0 auto" >
        <Link href="/other_pages/about_chikets">
          <HStack>
            <Image src={ticket_icon} height={10} width={10} color={main_color} />
            <Text fontSize={20}>無料入場チケット</Text>
          </HStack>
        </Link>
        <Link href="/official_event_guide">
          <HStack>
            <Image src={official_icon} height={10} width={10} objectFit="cover" />
            <Text fontSize={20}>本部企画一覧</Text>
          </HStack>
        </Link>
        <Link href="/events">
          <HStack>
            <Search2Icon height={10} width={10} padding={1} color={main_color} />
            <Text fontSize={20}>イベントを探す</Text>
          </HStack>
        </Link>
        <Link href="/time_table">
          <HStack>
            <TimeIcon height={10} width={10} padding={1} color={main_color} />
            <Text fontSize={20}>タイムテーブル</Text>
          </HStack>
        </Link>
        <Link href="/places_guide">
          <HStack>
            <Image src={place_icon} height={10} width={10} color={main_color} />
            <Text fontSize={20}>場所一覧</Text>
          </HStack>
        </Link>
      </VStack>
    </LayoutFrame>
  )
}
export default NotFoundPage
