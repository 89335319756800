import * as React from "react"
import { Text, Heading, Image, Box, VStack, Link, HStack } from "@chakra-ui/react"
import LayoutFrame from "../components/layout_frame"
import { GoogleMapDatas, Place } from "../data/places"
import ShowMap from "../components/show_map"

interface map {
    place_name: string,
    map_data: Place,
}

import whole_area_map from "../images/whole_area_map.jpg"

import ad_divide8_1 from "../images/ad_images/divide8/8_1.jpg"
import ad_divide8_2 from "../images/ad_images/divide8/8_2.jpg"
import ad_divide8_3 from "../images/ad_images/divide8/8_3.jpg"
import ad_divide8_4 from "../images/ad_images/divide8/8_4.jpg"

const PlaceGuide = () => {
    return (
        <LayoutFrame>
            <Heading>場所一覧</Heading>
            <Image src={whole_area_map} />
            <VStack>
                <PlaceLink name="エリアA" link="area_A" />
                <PlaceLink name="エリアB" link="area_B" />
                <PlaceLink name="エリアC" link="area_C" />
                <PlaceLink name="エリアD" link="area_D" />
                <PlaceLink name="エリアE" link="area_E" />
                <PlaceLink name="全学講義棟1号館" link="zengaku1" />
                <PlaceLink name="全学講義棟2号館" link="zengaku2" />
                <PlaceLink name="教育学部A棟" link="kyouiku_A" />
                <PlaceLink name="教養学部棟" link="kyouyou" />
                <PlaceLink name="サル山" link="saruyama" />
                <PlaceLink name="メインステージ" link="main_stage" />
                <PlaceLink name="総合体育館" link="sougou_taiku" />
                <PlaceLink name="大学会館" link="daigaku_kaikan" />
                <PlaceLink name="第一体育館" link="1taikukan" />
                <PlaceLink name="弓道場" link="kyuudou" />
                <PlaceLink name="第二食堂" link="2syoku" />
                
            </VStack>

            <Image src={ad_divide8_1} />
            <Image src={ad_divide8_2} height={150} />
            <HStack>
                <Image src={ad_divide8_3} width="50%" />
                <Image src={ad_divide8_4} width="50%" />
            </HStack>

        </LayoutFrame>
    )
}
export default PlaceGuide

const PlaceLink = ({ name, link }: { name: string, link: string }) => {
    return (
        <Link href={"/place/" + link}>
            <Text as="u">{name}</Text>
        </Link>
    )
}