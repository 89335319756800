import * as React from "react"
import { Text, Heading, Image, Button } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"
import LayoutFrame from "../../components/layout_frame"
import { accent_color } from "../../components/UItheme"

const AboutChikets = () => {
    return (
        <LayoutFrame>
            <Heading margin={5}>無料入場チケット</Heading>
            <Heading as="h2" fontSize={20} marginTop={5} marginBottom={5}>入場には無料チケットが必要です</Heading>
            <Text>
                第73回むつめ祭では、感染症対策のため、入場には無料チケットが必要になります！<br />
                <br />
                事前に無料チケットを入手いただけますと、当日スムーズにご入場いただけます。<br />
                混雑緩和のため、入手された状態でのご来場にご協力お願いいたします。<br />
                <br />
                なお、スマホをお待ちでない方等用として、紙の無料チケットも入場口付近にて入手いただけます。<br />
                (混雑が予想されますので、スマホをお持ちの方は電子無料チケットの入手をお願いします。)<br />
            </Text>
            
            <Text>
                Q. 現役の埼⽟⼤学⽣，埼⽟⼤学院⽣も，⼊場チケットは必要ですか？<br />
                A. はい，必要です。教職員の⽅々も，できる限り⼊場チケットの⼊⼿にご協⼒お願いいたします。
            </Text>

            <a href="https://passmarket.yahoo.co.jp/event/show/detail/02nyuasr7wm21.html">
                <Button size="lg" backgroundColor={accent_color} marginTop={5} marginBottom={5}>チケット発行はこちら</Button>
            </a>
        </LayoutFrame>
    )
}
export default AboutChikets