import { Box, Center, Text, VStack } from "@chakra-ui/react";
import exp from "constants";
import React from "react";
import { accent_color } from "./UItheme";


const SougoLink = ({ univ_name, fes_name, date, link }: { univ_name: string, fes_name: string, date: string, link: string }) => {
    return (
        <Box borderWidth={1} borderRadius="lg" borderColor={accent_color} padding={5} width="100%">
            <a href={link}>

                    <VStack>
                        <Text fontSize={25} margin="0 auto">{fes_name}</Text>
                        <Text>{univ_name}</Text>
                        <Text>{"開催日程：" + date}</Text>
                        {/*URLは空白のない半角なので折り返し場所を指定しないと折り返さない*/}
                        <Text as="u" wordBreak="break-all">{link}</Text>
                    </VStack>
            </a>
        </Box>
    )
}
export default SougoLink