import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

const Bingo = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>ビンゴ大会</Heading>
            <Text>　むつめ祭最終日には、無料で参加いただけるビンゴ大会を開催いたします。むつめ祭最後の思い出に、ぜひお立ち寄りください！豪華景品が当たるチャンスです！<br/>
                開催日：11/27(日)<br/>
                開催時間：17:30~19:00（16:30からビンゴカードの配布を開始します）<br/>
                開催場所：サル山（雨天時は教育学部棟C1教室）</Text>
        </OfficialEventLayoutFrame>
    )
}
export default Bingo