import * as React from "react"
import { Text, Heading, Image} from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import StumpRallyImage from "../../images/official_event/stamp_rally.jpg"

const StampRally = () => {
    return(
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>スタンプラリー</Heading>
            <Image src={StumpRallyImage} />
        </OfficialEventLayoutFrame>
    )
}
export default StampRally