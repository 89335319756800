import * as React from "react"
import { Text, Heading, Image, Box } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"
import LayoutFrame from "../../components/layout_frame"
import { accent_color } from "../../components/UItheme"

const ContactUs = () => {
    return (
        <LayoutFrame>
            <Box borderWidth={1} borderRadius="lg" borderColor={accent_color} padding={5} width="80%" marginTop={10}>
                <Text>連絡先</Text>
                <Text size="sm">　埼玉大学ではむつめ祭に関する質問は受け付けておりません。むつめ祭に関する内容は必ずむつめ祭常任委員会へ確認してください。</Text>
                <Text size="sm" marginTop={3}>むつめ祭常任委員会連絡先
                <br />メール： staff @ mutsume.com （@の前後に半角スペースを入れてあります）
                <br />電話： 048 - 858 - 2112（電話はつながりにくいためメールでのご連絡をお願いします）</Text>
            </Box>
        </LayoutFrame>
    )
}
export default ContactUs