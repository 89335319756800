import { AspectRatio, Box, Heading, Image, Text, Link } from "@chakra-ui/react";
import React from "react";
import { Place, GoogleMapDatas, place_name_to_alphabet } from "../data/places";
import LayoutFrame from "./layout_frame";

const ShowMap = ({ place }: { place: Place }) => {

    let map = GoogleMapDatas.get(place)
    if (map === undefined) {
        console.error("map of palce='" + place + "' is undefined")
        return (<p>err: map of this place is undefined</p>)
    } else if (map == 'original map') {
        return (
            <Box marginTop={5} marginBottom={5}>
                <Heading>{place + "の地図"}</Heading>
                <Text>マップ掲載までもうしばらくお待ちください</Text>
            </Box>

        )

        /*return (
            <Text>{"Show Map Image of " + place}</Text>
        )*/
    }

    return (
        <Box marginTop={5} marginBottom={5}>
            <Link href={"/place/"+ place_name_to_alphabet.get(place)}>
                <Heading>{place + "の地図"}</Heading>
            </Link>
            <AspectRatio width="100%" maxH={600} ratio={1}>
                <iframe
                    title="map"
                    src={map}
                    allowFullScreen
                />
            </AspectRatio>

        </Box>


    )
}
export default ShowMap;