//declare module '*/event_list.json' {
/*    const data: EventListJson

    export default data;
}*/

import { Place } from "./places"

export type EventJson = {
    version: string,
    events: Event[],
}

export interface Event {
    event_name: string,
    exhibitor: string,
    id: string,
    place: string,
    map: Place,
    event_type: EventType,
    contents: string,
    introduction: string,
}

export const EventType = [
    "パフォーマンス",
    "飲食",
    "展示",
    "体験",
    "その他",
 ] as const;
export type EventType = typeof EventType[keyof typeof EventType];