import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import CyuusenImage from "../../images/official_event/cyuusen.png"

const Cyuusen = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>埼大生抽選</Heading>
            <Heading as="h2" fontSize={20}>埼大生のみなさん！！むつめ祭に行こう！！</Heading>

            <Text>学生証の提示で，1人1回豪華景品があたる抽選ができます！<br />
            <br />
                詳しくは，こちら↓（埼大生抽選特設フォーム）</Text>
            <a href="https://sites.google.com/mutsume.com/saidaiseityusen/ホーム">
                <Text as="u">https://sites.google.com/mutsume.com/saidaiseityusen/ホーム</Text>
            </a>

            <Text>
                <br />
                景品については，公式Twitter（
                <a href="https://twitter.com/mutsume_fes">
                    <Text as="u">https://twitter.com/mutsume_fes</Text>
                </a>
                ）や公式Instagram（
                <a href="https://www.instagram.com/mutsume_fes/?hl=ja">
                    <Text as="u">https://www.instagram.com/mutsume_fes/?hl=ja</Text>
                </a>
                ）で
                開中！！ぜひチェックしてみてください！
            </Text>

            <Image src={CyuusenImage} />

        </OfficialEventLayoutFrame>
    )
}
export default Cyuusen