import { TriangleUpIcon } from "@chakra-ui/icons"
import { match } from "assert"

//``を使うと改行が許容され、さらに表示上の改行がそのまま改行として扱われる
export enum DataName {
    event_name = 0,
    place = 1,
    day = 2,
    start_h = 3,
    start_m = 4,
    end_h = 5,
    end_m = 6,
    start_time_sum = 7,
    end_time_sum = 8,
    margin_top = 9
}
export class TimeTableRow {
    public event_name: string
    public place: string
    public day: string
    public start_h: string
    public start_m: string
    public end_h: string
    public end_m: string
    public this_event_time_length: number
    public margin_top: number

    constructor(row: string) {
        let datas = row.split(",")

        this.event_name = datas[0]
        this.place = datas[1]
        this.day = datas[2]
        this.start_h = datas[3]
        this.start_m = datas[4]
        this.end_h = datas[5]
        this.end_m = datas[6]
        this.this_event_time_length = Number(datas[8]) - Number(datas[7])
        this.margin_top = Number(datas[9])
    }
}

export class TimeTableData {

    getList(day: string, place: "saruyama"|"nisyoku"|"kaikann") {
        const strings = [
            [this.saruyama_day1, this.saruyama_day2, this.saruyama_day3],
            [this.kaikann_day1, this.kaikann_day2, this.kaikann_day3],
            [this.nisyoku_day1, this.nisyoku_day2,this.nisyoku_day3],
        ]
        let place_id: number = 0
        if(place == "saruyama") {
            place_id = 0
        }else if(place == "kaikann") {
            place_id = 1
        }else if(place == "nisyoku") {
            place_id = 2
        }
        const day_id = Number(day) - 1
        const string = strings[place_id][day_id]
        //usage
        /*for row in list {
            row[enum DataName]
        }*/
        const rows = string.split("\n")
        const list: TimeTableRow[] = rows.map((row) => new TimeTableRow(row))
        return list
    }

//インデント上げるな。空白が入る
    public saruyama_day1 =
`大塚まりな(女子大生歌手)＠サル山,サル山,11月25日,12,35,13,55,155,235,155
エレクトーンライブ@サル山,サル山,11月25日,17,15,17,50,435,470,200`

    public saruyama_day2 =
`少林寺拳法部,サル山,11月26日,10,37,10,53,37,53,37
Swing Cube Jazz Orchestra,サル山,11月26日,11,10,11,50,70,110,17
CHOCOLETZ＠サル山,サル山,11月26日,12,10,13,20,130,200,20
無外流居合兵道同好会,サル山,11月26日,13,30,13,50,210,230,10
めらんかーむ,サル山,11月26日,14,05,14,25,245,265,15
演武会,サル山,11月26日,14,45,15,05,285,305,20
Arpeggio＠サル山,サル山,11月26日,15,40,16,50,340,410,35
エレクトーンライブ@サル山,サル山,11月26日,17,15,17,50,435,470,25`

    public saruyama_day3 =
`少林寺拳法部,サル山,11月27日,10,37,10,53,37,53,37
桃犬,サル山,11月27日,11,20,12,50,80,170,27
埼玉大学合気道部,サル山,11月27日,13,20,14,00,200,240,30
音楽研究会のび民踊お囃子班,サル山,11月27日,14,30,15,50,270,350,30
yamii.,サル山,11月27日,16,10,16,50,370,410,20
ビンゴ,サル山,11月27日,17,30,19,00,450,540,40`

    public nisyoku_day1 =
`Jazz Spot＠第二食堂,第二食堂テラス,11月25日,12,05,12,55,125,175,125`

    public nisyoku_day2 =
`Jazz Spot＠第二食堂,第二食堂テラス,11月26日,12,05,12,55,125,175,125`
    public nisyoku_day3 =
`Jazz Spot＠第二食堂,第二食堂テラス,11月27日,12,05,12,55,125,175,125
Arpeggio＠第二食堂,第二食堂テラス,11月27日,14,05,14,50,245,290,70
エレクトーンライブ@第二食堂,第二食堂テラス,11月27日,16,15,16,50,375,410,85`

    public kaikann_day1 =
`吹奏楽部,大学会館,11月25日,13,05,13,25,185,205,185
吹奏楽部,大学会館,11月25日,14,05,14,25,245,265,40
吹奏楽部,大学会館,11月25日,15,05,15,25,305,325,40`

    public kaikann_day2 =
`趣深い音色をあなたへ　和楽器コンサート,大学会館,11月26日,10,45,11,45,45,105,45
大塚まりな(女子大生歌手)@大学会館,大学会館,11月26日,13,05,14,25,185,265,80
吹奏楽部コラボレーションコンサート,大学会館,11月26日,15,20,16,30,320,390,55`

    public kaikann_day3 =
`桜チアダンスクラブ SAKURA ANGELS,大学会館,11月27日,11,00,11,30,60,90,60`
}





