import * as React from "react"
import { Text, Heading, Image, Link, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Button, Center } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import image from "../../images/mutsume_campus_image.jpg"
import { accent_color } from "../../components/UItheme"
import { ExternalLinkIcon } from "@chakra-ui/icons"

const MutsumeCampus = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>むつめキャンパス</Heading>
            <Image src={image} marginBottom={10} />
            <Text>　むつめキャンパスでは、受験生や埼玉大学に興味のある方に向けて以下の3つの企画を行っております！</Text>
                <Accordion allowMultiple marginTop={5} marginBottom={5}>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    ①受験相談会
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            受験相談会<br/>
                            在校生のリアルな声を聞くことができます。<br/>
                            入試資料や受験生応援冊子を配布予定です。<br/>
                            土日は入試課の職員の方も相談員としていらっしゃいます。<br/>
                            <br/>
                            場所<br/>
                            <Link href="/place/kyouiku_A">
                                <Text as="u">教育学部A棟2階210教室</Text>
                            </Link><br/>
                            <br/>
                            日時<br/>
                            11/25（金）12:00〜16:30<br/>
                            11/26（土）10:00〜16:30<br/>
                            11/27（日）10:00〜16:30
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    ②模擬講義
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            模擬講義<br/>
                            むつめ祭でしか聞くことのできない授業です。<br/>
                            <br/>
                            場所<br/>
                            <Link href="/place/kyouiku_A">
                                <Text as="u">教育学部A棟2階214教室</Text>
                            </Link><br/>
                            <br/>
                            日時<br/>
                            11/26（土）13:00〜14:00<br/>
                            「地球の表と裏で気温と気圧が一致する地点はあるか？」<br/>
                            理学部数学科　佐藤洋平 先生<br/>
                            <br/>
                            11/27（日）14:00〜15:00<br/>
                            「身近なもので教材を作ろうー国際理解を深める授業に向けてー」<br/>
                            教育学部　磯田三津子 先生
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    ③キャンパスツアー
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            キャンパスツアー<br/>
                            在校生がキャンパスをご案内します。<br/>
                            所要時間30分程度<br/>
                            雨天時は中止<br/>
                            <br/>
                            場所<br/>
                            <Link href="/place/daigaku_kaikan">
                                <Text as="u">大学会館前集合</Text>
                            </Link>
                            <br/>
                            <br/>
                            11/26（土）11:00〜<br/>
                            11/27（日）11:00〜
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Text>例年、1000人以上（弊委員会による見込み）が来場する大人気の企画となっており、来ていただければ、埼大生を最大限に体験できること間違いないでしょう！
                    「予約不要」「料金無料」なので、ぜひぜひお越しください！</Text>

                <Heading as="h2" fontSize={20} marginTop={5} marginBottom={5}>質問募集中！</Heading>
                <Text>　講義や大学生活、サークルのことなど、質問や聞きたいことをなんでも送って下さい！<br/>
                    ※当日、来場していただける皆様にお答えする形となることご了承下さい</Text>
                <a href="https://forms.gle/CQGu999sdkH26GDM7">
                    <Center marginTop={5}>
                        <Button backgroundColor={accent_color}>質問を送る！<ExternalLinkIcon/></Button>
                    </Center>
                    
                </a>
                
        </OfficialEventLayoutFrame>
    )
}
export default MutsumeCampus