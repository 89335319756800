import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import frea_market from "../../images/official_event/frea_market.jpg"

const FreaMarket = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>フリーマーケット</Heading>
            <Text marginBottom={5}>むつめ祭では，フリーマーケットを開催いたします！<br />
                100を超える数の出店が並びます！ぜひ，足をお運びください！！<br />
                <br />
                【開催日】全日<br />
                【開催時間】開場時間〜16:30<br />
                【開催場所】イノベ棟前，グラウンド</Text>

            <Image margin="0 auto" width="90%" src={frea_market} />
        </OfficialEventLayoutFrame>
    )
}
export default FreaMarket