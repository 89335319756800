import { Heading, HStack } from "@chakra-ui/react"
import React from "react"
import LayoutFrame from "../../components/layout_frame"
import ShowMap from "../../components/show_map"
import { filterEventsByPlace, getAllEvents } from "../../data/event_selector"
import { Place } from "../../data/places"
import { Event } from "../../data/event_types"
import { EventList } from "../../components/event_list"

import { Image } from "@chakra-ui/react"
import kaikan from "../../images/map/kaikan.png"

import ad_divide12_9 from "../../images/ad_images/divide12/12_9.jpg"
import ad_divide12_10 from "../../images/ad_images/divide12/12_10.jpg"


const Zengaku1 = () => {
    const place: Place = "大学会館"
    const events_here: Event[] = filterEventsByPlace(getAllEvents(),place)
    return(
        <LayoutFrame>
            <Heading>{place}</Heading>
            <Image src={kaikan}/>

            <HStack>
                <Image src={ad_divide12_9} width="50%" />
                <Image src={ad_divide12_10} width="50%" />
            </HStack>

            <Heading paddingTop={5}>{place + "で行われるイベント一覧"}</Heading>
            <EventList events={events_here} />
        </LayoutFrame>
    )
}
export default Zengaku1