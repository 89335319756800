import * as React from "react"
import { Text, Heading, Image, Center, Link, Button } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import bus_times from "../../images/bus_times.png"
import { accent_color } from "../../components/UItheme"

const BusTime = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>紙パンフのPDF配布</Heading>
            <Text>こちらから印刷配布されているパンフレットのpdfをダウンロードできます。</Text>
            <Text>このサイトには紙パンフと同様の情報が掲載されているため、必ずしもこのpdfをダウンロードする必要はありません。</Text>
            <Text  marginTop={5}>pdfのデータ量は28MBとやや大きめですので回線速度と通信量にご注意ください。</Text>
            <Center marginTop={10}>
                <Link href="/73panf.pdf">
                    <Button backgroundColor={accent_color}>ダウンロード(28MB)</Button>
                </Link>
            </Center>
            
        </OfficialEventLayoutFrame>
    )
}
export default BusTime