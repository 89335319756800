import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import OpeningImage from "../../images/official_event/opening.jpg"

const Opening = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>オープニングイベント</Heading>
            
            <Text>むつめ祭の始まりを告げる毎年恒例のオープニングイベント！！<br/>
                オリジナルビンゴでは豪華景品がもらえるチャンス...！？<br/>
                埼大の団体によるパフォーマンスも必見です👀<br/>
                <br/>
                開催日時：11/25(金)　12:00-13:20(＊雨天中止)<br/>
                開催場所：メインステージ</Text>
            <Image src={OpeningImage} />
        </OfficialEventLayoutFrame>
    )
}
export default Opening