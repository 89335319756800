import * as React from "react"
import { Box, Button, Center, ChakraProvider, Container, Heading, HStack, Image, Link, Text, VStack } from "@chakra-ui/react"

import bud from "../images/bud.png"

//gatsby degelop -H 172.17.0.3


import { accent_color, main_color, my_theme } from "../components/UItheme"
import LayoutFrame from "../components/layout_frame"
import OfficialEventList from "../components/official/official_event_list"

import HeroImage from "../images/hero_image.png"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"
import Header from "../components/header"
import EventListHorizontal from "../components/event_list_horizontal"
import { selectRandomEvents, getRandomEventsByType, getAllEvents } from "../data/event_selector"
import { ExternalLinkIcon, Search2Icon, TimeIcon } from "@chakra-ui/icons"

import ticket_icon from "../images/icon_ticket.svg"
import place_icon from "../images/icon_place.svg"
import SougoLink from "../components/sougo_link"

import MutsumeChan from "../images/character_mutsumechan.png"
import iinsyoukai from "../images/iinsyoukai.png"

//ad
import ad_double_1 from "../images/ad_images/double_page/double_1.jpg"
import ad_full_1 from "../images/ad_images/full_page/full_1.jpg"
import ad_full_2 from "../images/ad_images/full_page/full_2.jpg"

const IndexPage: React.FC = () => {
  return (
    <ChakraProvider theme={my_theme}>
      <Helmet>
        <title>第７３回むつめ祭公式サイト</title>
        <meta name="description" content="むつめ祭は埼玉大学の学園祭です。第７３回むつめ祭は１１月２５（金）から三日間開催されます。今年のテーマは「彩の芽」です。" />
        <meta charSet="utf-8" />
        <html lang="ja" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="第７３回むつめ祭公式サイト" />

        {/**これはtwitterでも使われる */}
        <meta name="og:title" content="第７３回むつめ祭公式サイト" />
        <meta name="og:description" content="むつめ祭は埼玉大学の学園祭です。第７３回むつめ祭は１１月２５（金）から三日間開催されます。今年のテーマは「彩の芽」です。" />
        {/**これは絶対パスを要求する。 */}
        <meta name="og:image" content="/hero_image_og.png" />

        {/*twitterのサムネ */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mutsume_fes" />
        <meta name="twitter:image" content="/hero_image_og.png" />

        <link href="https://fonts.googleapis.com/css?family=Sawarabi+Mincho" rel="stylesheet"></link>
      </Helmet>

      <header><Header /></header>
      <main>
        {
          //centerのmarginにするとなぜかheaderより上にできる。頭湧いてんのか？
        }
        <Box height="50px"></Box>

        
        <Center margin={10}>
            <VStack>
              <Heading>第73回むつめ祭は終了しました。</Heading>
              <Text>ご来場ありがとうございました。</Text>
              <Image src={MutsumeChan} height={200}/>
            </VStack> 
        </Center>

        <Center >
          <Image src={HeroImage} width="100%" maxWidth="4xl" />
        </Center>



        <Container maxWidth="container.lg">

          {
            //ここは動く。
            //ただし、width="100%"の中に入れないと右にオーバーする
          }
          <Box width="100%">
            <EventListHorizontal events={selectRandomEvents(getAllEvents(), 10)} />
          </Box>

          
          


          {
            //ここはバグる！横スクロールが機能しないで全部横並びにされる
            //位置を真ん中に合わせようとしたときにもcss効かなくて手こずったからCenterがかなり素性悪いなこれ
            //<EventListHorizontal events={getRandomEvents(10)}/>
          }

          <VStack marginStart={5} marginEnd={5}>
            <Heading paddingTop="40px">入場には無料チケットが必要です</Heading>
            <Image src={ticket_icon} color={main_color} height={40} />
            <Text>
              第73回むつめ祭では、感染症対策のため、入場には無料チケットが必要になります！<br />
              <br />
              事前に無料チケットを入手いただけますと、当日スムーズにご入場いただけます。<br />
              混雑緩和のため、入手された状態でのご来場にご協力お願いいたします。<br />
              <br />
              なお、スマホをお待ちでない方等用として、紙の無料チケットも入場口付近にて入手いただけます。<br />
              (混雑が予想されますので、スマホをお持ちの方は電子無料チケットの入手をお願いします。)<br />
            </Text>
            <Box borderWidth={1} borderRadius="lg" borderColor={accent_color} padding={5}>
              <Text>
                Q. 現役の埼⽟⼤学⽣，埼⽟⼤学院⽣も，⼊場チケットは必要ですか？<br />
                A. はい，必要です。教職員の⽅々も，できる限り⼊場チケットの⼊⼿にご協⼒お願いいたします。
              </Text>
            </Box>


            <a href="https://passmarket.yahoo.co.jp/event/show/detail/02nyuasr7wm21.html">
              <Button size="lg" backgroundColor={accent_color} marginTop={5} marginBottom={5}>チケット発行はこちら<ExternalLinkIcon /></Button>
            </a>

            {/**paddingは上下だけにつけること。左右につけるとスマホとかで文字サイズが入りきらないで折り返しが発生 */}
            <VStack paddingY="30px">
              <Heading fontSize="4xl">７３回テーマ</Heading>
              <Heading fontSize="5xl">「彩の<Box as="span" color={accent_color}>芽</Box>」</Heading>
            </VStack>


            {/*提供元、https://tsukatte.com/sprout/、権利表記必要なし */}
            <Image src={bud} width="60%" />

            <Text fontSize="xl">＜２０２２年度 開催日＞</Text>
            <Text>１１月２５日（金）</Text>
            <Text>１１月２６日（土）</Text>
            <Text>１１月２７日（日）</Text>

            <Text>対面開催を予定しています</Text>
            <Text>（開催形態は変更される可能性があります）</Text>




            {/*marginだと反応しないけどpaddingだとする。親要素の関係っぽい？ */}
            <Heading paddingTop="40px">テーマの意味</Heading>
            <Text>彩の芽（さいのめ）</Text>
            <Text>　「彩の国さいたま」のキャッチコピーにもある「彩」という字を使うことで、埼玉大学が埼玉県を代表する大学であることへの誇りや、むつめ祭が明るく華やかな学園祭となることへの期待などを表しています。「芽」は始まりの象徴であり、2年間の休止を経てこれから成長していくむつめ祭への希望の思いが込められています。また，「さいのめ」という読みは「賽の目」と同音であり、サイコロは６つの目があることから「むつめ」にもつながるというユーモアも混ざったテーマです。</Text>



            <Heading paddingTop="40px">本部企画</Heading>
            <OfficialEventList />

            <Link href="/events" paddingTop="40px">
              <VStack width="300px" padding={30} borderColor={accent_color} borderWidth="2px" borderRadius="lg">
                <Heading>イベントを探す</Heading>
                <Search2Icon height={40} width={40} padding={3} color={main_color} />

              </VStack>
            </Link>
            <Link href="/time_table" paddingTop="40px">
              <VStack width="300px" padding={30} borderColor={accent_color} borderWidth="2px" borderRadius="lg">
                <Heading>タイムテーブル</Heading>
                <TimeIcon height={40} width={40} padding={3} color={main_color} />

              </VStack>
            </Link>
            <Link href="/places_guide" paddingTop="40px">
              <VStack width="300px" padding={30} borderColor={accent_color} borderWidth="2px" borderRadius="lg">
                <Heading>場所一覧</Heading>
                <Image src={place_icon} height={40} width={40} color={main_color} />

              </VStack>
            </Link>



            {/*
            <Heading paddingTop="40px">フリーマーケット参加募集</Heading>
            <Text>
              今年度の第 73 回むつめ祭は 11 月 25 日(金)～27 日(日)の 3 日間で開催いたします。今年度も本部企画の 1 つとしてフリーマーケットを下記の要領で開催する運びとなりました。
              今年度も例年通り 3 日間での開催でございます。もしご都合が宜しければ是非ご応募くださいますようお願い致します。<br />
              <br />
              日 時：令和 4 年 11 月 25 日(金) (1 日目)12:30～16:30<br />
              26 日(土) (2 日目)10:30～16:30<br />
              27 日(日) (3 日目)10:30～16:30<br />
              準備時間の都合上 1 日目の時間が短くなっております。<br />
              場 所：イノベーションセンター前，埼玉大学陸上競技トラック内<br />
              出店料：無料<br />
              応募締切:11月4日（10日から変更になりました）<br />
              区 画：幅 2.5m×奥行き 1.8m(予定(変更の可能性あり))<br />
              区画数：全日程 100 区画 (応募者多数の場合抽選，変更の可能性あり)<br />
              主 催：第 73 回むつめ祭常任委員会
            </Text>
            <a href="https://forms.gle/B7ZpYZR2v5oQDSvE8">
              <Button backgroundColor={accent_color}>フリーマーケット出店申し込み
                <ExternalLinkIcon />
              </Button>
            </a>
            */}

            <Heading paddingTop="40px">受験相談の質問募集中！</Heading>
            <Text>　むつめ祭オープンキャンパス企画「むつめキャンパス」では、受験生向けの企画として受験相談会を行っています！講義や大学生活、サークルのことなど、質問や聞きたいことをなんでも送って下さい！<br />
              ※当日、来場していただける皆様にお答えする形となることご了承下さい</Text>
            <a href="https://forms.gle/CQGu999sdkH26GDM7">
              <Center>
                <Button backgroundColor={accent_color}>質問を送る！
                  <ExternalLinkIcon />
                </Button>
              </Center>
            </a>
            <a href="official/mutsume_campus"><Text as="u">本部企画「むつめキャンパス」についてはこちら</Text></a>


            <Heading paddingTop="40px">公式キャラクター</Heading>
            <Heading as="h2" fontSize={20}>むつめちゃん</Heading>
            <Image src={MutsumeChan} width="80%" />
            <Heading as="h2" fontSize={20}>🌱プロフィール🌱</Heading>
            <Text>
              誕生日：6月2日<br />
              性別：不明<br />
              身長：たぶん大きい<br />
              体重：62トン<br />
              性格：恥ずかしがり屋で無口。本当はみんなと仲良くなりたくて頑張ってます。<br />
              特徴：普段はみんなには見えないけど，むつめ祭の時期になると現れるかも！</Text>
            <a href="/official/mascot">
              <Text as="u">詳しくはこちら</Text>
            </a>

            {/**Vstackはいったん切る */}
          </VStack>
          <Heading paddingTop="40px">食販のイベント</Heading>
          <Box width="100%">
            <EventListHorizontal events={getRandomEventsByType("飲食", 10)} />
          </Box>
          <Heading paddingTop="40px">パフォーマンスのイベント</Heading>
          <Box width="100%">
            <EventListHorizontal events={getRandomEventsByType("パフォーマンス", 10)} />
          </Box>
          <VStack marginStart={5} marginEnd={5}>



            

            <Heading paddingTop="40px">個人協賛</Heading>
            <Text>うどんちゃん　大野浩史　重村佳太郎　けやき学生祭実行委員　小原直希　サカキ　坂本美咲　佐藤隆彦　佐久間南海　十文字千花　ジガディラス・ウルザケルガ　第71回むつめ祭常任委員会協賛部署長 丹治弘武　ハピネスルーム5　東駿佑　保坂文彦　真栄城圭里　吉田優志　山田屋　ｋ.c　sugar　匿名</Text>
            <Text>（五十音順、敬称略）</Text>

            <Heading paddingTop="40px">他大学相互リンク</Heading>
            <VStack width="80%">
              <SougoLink fes_name="立教大学学園祭 St.Paul's Festival" univ_name="立教大学" date="11月4日(金)、5日(土)、6日(日)" link="http://www.rikkyo.ne.jp/sgrp/spf/" />
              <SougoLink fes_name="常盤祭（ときわさい）" univ_name="横浜国立大学 常盤台キャンパス" date="10月28日(金)〜30日(日)" link="https://tokiwa22.ynu-fes.yokohama/" />
              <SougoLink fes_name="白門祭" univ_name="中央大学 多摩キャンパス" date="11/3㈭~11/6㈰" link="https://hakumonsai.com" />

            </VStack>

            <Image paddingTop="40px" src={ad_double_1}/>
            <HStack paddingTop="40px">
              <Image src={ad_full_1} width="50%"/>
              <Image src={ad_full_2} width="50%"/>
            </HStack>

            <Heading paddingTop="40px">委員長紹介</Heading>
            <Text>第73回むつめ祭常任委員会委員長の岩瀬将英です。
              <br />まず、第73回むつめ祭が開催をできることを喜ばしく思うとともに、日頃ご理解ご協力いただいております大学関係者のみなさま、桜区をはじめ多くの地域の方々に深く感謝申し上げます。これからもよろしくお願いいたします。
              <br />そして委員全員がむつめ祭を経験していない中、不安も多くあり、わからないこともたくさんありましたが、ここまで来られたのも委員のみんなの頑張りのおかげです。ありがとう！
              <br />我々としましても、第70回以降3年ぶりのむつめ祭を大変楽しみにしております。コロナもあり、例年とは大きく異なりますがそれでも、来ていただく方々が満足してくださるように努めてまいります。
              <br />・・・ついに、三年の時を経て「賽は投げられた」</Text>

            <Heading paddingTop="40px">委員紹介</Heading>
            <Image src={iinsyoukai} paddingBottom="40px" />

            


            <Box borderWidth={1} borderRadius="lg" borderColor={accent_color} padding={5} width="100%">
              <Heading>埼玉大学ホームカミングデー</Heading>

              <Text>11月26日に埼玉大学ホームカミングデーが行われます。今年はなんとむつめ祭との同時開催です！</Text>
              <Text>この機会にぜひ足を運んではいかがでしょうか。</Text>
              <a href="https://saitama2022hcd.com/">
                <Center paddingTop={2}>
                  <Button backgroundColor={accent_color}>詳細はこちら</Button>
                </Center>
              </a>
            </Box>
          </VStack>
        </Container></main>
      <footer><Footer /></footer>

    </ChakraProvider >
  )
}

export default IndexPage

