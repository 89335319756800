import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import nazotoki from "../../images/official_event/nazotoki.jpg"

const Nazotoki = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>謎解き</Heading>
            <Text marginBottom={5}>大学構内にある謎を解いて，ゴールを目指そう！！<br />
                お子様から大人まで楽しめます！！<br />
                いざ，フィールド型謎解きゲームへ<br />
                <br />
                【開催日】全日<br />
                【開催時間】開場時間〜17:00<br />
                【開催場所】集合場所：本部テント横</Text>

            <Image margin="0 auto" width="90%" src={nazotoki} />
        </OfficialEventLayoutFrame>
    )
}
export default Nazotoki