import * as React from "react"
import { Text, Heading, Image, Button, Center } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import MutsumeChan from "../../images/character_mutsumechan.png"
import { accent_color } from "../../components/UItheme"

const StumpRally = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>むつめ祭 新！公式メインキャラクター「むつめちゃん」</Heading>
            <Heading as="h2" fontSize={20}>第73回むつめ祭から新しいマスコットキャラクター「むつめちゃん」が登場！</Heading>

            <Image src={MutsumeChan} />

            <Heading as="h2" fontSize={20}>🌱プロフィール🌱</Heading> 
            <Text marginBottom={5}>
                誕生日：6月2日<br/>
                性別：不明<br/>
                身長：たぶん大きい<br/>
                体重：62トン<br/>
                性格：恥ずかしがり屋で無口。本当はみんなと仲良くなりたくて頑張ってます。<br/>
                特徴：普段はみんなには見えないけど，むつめ祭の時期になると現れるかも！<br/>
                <br/>
                むつめ祭当日はむつめちゃんと一緒に写真が撮れるフォトスポットもあります♪<br/>
                みなさんのご来場お待ちしております！！</Text>

            <a href="https://sites.google.com/mutsume.com/character-election/ホーム">
                <Center>
                    <Button backgroundColor={accent_color}>むつめ祭の他のキャラクター達はこちら！</Button>
                </Center>
            </a>
            
        </OfficialEventLayoutFrame>
    )
}
export default StumpRally