import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import EndingImage from "../../images/official_event/ending.jpg"

const Ending = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>エンディングイベント</Heading>
            
            <Text>むつめ祭史上初のエンディングイベント！！<br/>
                ダンス部のパフォーマンスと初めての試みであるむつめ祭グランプリの表彰式で、むつめ祭を締めくくります！<br/>
                初グランプリは一体誰の手に...！？<br/>
                <br/>
                開催日時：11/27(日)　19:00-20:00(＊雨天中止)<br/>
                開催場所：メインステージ</Text>
                <Image src={EndingImage} />
        </OfficialEventLayoutFrame>
    )
}
export default Ending