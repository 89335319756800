
//ここで新機能を実装してみて、ちゃんと動くものができたらそれぞれの場所に輸出していく

import { Checkbox, Text,Image, Heading, isChakraTheme, Select, RadioGroup, Stack, Radio } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import LayoutFrame from "../components/layout_frame"
import ShowMap from "../components/show_map"
import { filterEventsByPlace } from "../data/event_selector"
import { Place } from "../data/places"
import { Event, EventType } from "../data/event_types"
import { EventList } from "../components/event_list"
import { getPlaceholderProps } from "gatsby-plugin-image/dist/src/components/hooks"


//slick
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Test = () => {

    const data_mutsume_com = "https://data.mutsume.com/"

    const json_url = data_mutsume_com + "images.json"

    //npm install react-slick
    //npm install slick-carousel
    const [ images, setImages ] = React.useState<string[]>([])
    useEffect(() => {
        fetch(json_url, {mode: 'cors'})
        .then(response => response.json())
        .then(json => {
            setImages(json.images)
        })
    }, [])
    return(
        <LayoutFrame>
            <Image src={images[0]} />
            <Slider
                dots={true}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}>
                {
                    images.map((image_name, index) => {
                        console.log("render image: " + image_name)
                        const image_url = data_mutsume_com + image_name
                        return(
                            <Image src={image_url} key={index} />
                        )
                    })
                }
            </Slider>
            <Slider dots={true}
                infinite={true}
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>

        </LayoutFrame>
    )
}
export default Test
