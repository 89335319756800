import * as React from "react"
import { Text, Heading, Image, Box, Link } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import kodomokikaku from "../../images/official_event/kodomokikaku.jpg"

const KodomoKikaku = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>こどもきかく＆こどもラリー</Heading>

            <Image paddingBottom={5} margin="0 auto" width="90%" src={kodomokikaku} />
            
            <Heading as="h2" fontSize={20} marginBottom={3}>１．こどもきかく</Heading>
            <Text>
                場所：<a href="/place/2syoku"><Text as="u">第二食堂</Text></a>
                    ・保健センター前
                    <br/>
                時間：25日12:00~17:00<br/>
                　　　26、27日10:00~17:00<br/>
                <br/>
                　こどもきかくでは第2食堂・保健センター前で幼児〜小学生を対象にしたこども向けのゲーム・工作体験の企画を行います。今年度は「冒険」をテーマに、小さな探検家たちに様々なミッションをクリアしてもらいます！
                具体的には、<br/>
                ①トロッコに乗って的当てや輪投げを行うゲーム企画「小さな探検家の大冒険」<br/>
                ②大きな空気砲から出る煙を使ったフォトスポット「ドカンと！きょだいくうきほう」<br/>
                ③ピラミッドや壁画など、遺跡をイメージした立体迷路「メリン遺跡」<br/>
                ④こどもたちのつくる折り紙で森に生き物を増やしていく工作企画「あつまれ！折り紙の森！」<br/>
                ⑤カプセルに入ったお宝を探し当てる「トレジャーハンティング」<br/>
                ⑥工作体験「スライムをつくろう！」<br/>
                の以上6つの企画を運営します。
            </Text>

            <Heading as="h2" fontSize={20} marginTop={5} marginBottom={3}>２．こどもラリー</Heading>
            <Text>
                場所：
                    <a href="/place/kyouiku_A"><Text as="u">教育学部A棟</Text></a>
                    ・<a href="/place/2syoku"><Text as="u">第二食堂</Text></a><br/>
                時間：25日12:00~17:00<br/>
                　　　26、27日 10:00~17:00<br/>
                <br/>
                　本部企画に加え、各団体によるこども向けの企画を周ることで景品をもらえるスタンプラリー「こどもラリー」も行います。
                各団体の出店場所と内容は以下の通りです。
            </Text>
            
            <Box marginTop={5}>
                <Text fontSize={18}>・WDB株式会社エウレカ社</Text>
                <Link href={"/place/2syoku"}>
                    <Text as="u">場所:第二食堂</Text>
                </Link>
                <Text>内容:工作体験</Text>
            </Box>

            <Box marginTop={5}>
                <Text fontSize={18}>・埼玉大学イルミネーションプロジェクト実行委員会</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室:教育学部A棟：114番教室</Text>
                </Link>
                <Text>内容:工作体験</Text>
            </Box>
            <Box marginTop={5}>
                <Text fontSize={18}>・いろはにほへと</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室:教育学部A棟112番教室</Text>
                </Link>
                <Text>内容:化石の展示</Text>
            </Box>
            <Box marginTop={5}>
                <Text fontSize={18}>・埼玉大学鐵道研究会</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室:教育学部A棟：325番教室</Text>
                </Link>
                <Text>内容:プラレールや鉄道模型の展示、模型の運転体験</Text>
            </Box>
            <Box marginTop={5}>
                <Text fontSize={18}>・美術部</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室: 教育学部A棟：212番教室</Text>
                </Link>
                <Text>内容:絵画やイラストの展示、作品販売</Text>
            </Box>
            <Box marginTop={5}>
                <Text fontSize={18}>・BBS会</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室:教育学部A棟：203番教室</Text>
                </Link>
                <Text>内容:縁日</Text>
            </Box>
            <Box marginTop={5}>
                <Text fontSize={18}>・SPL</Text>
                <Link href="/place/kyouiku_A">
                    <Text as="u">教室:教育学部A棟：113番教室</Text>
                </Link>
                <Text>内容:ポケモンカードゲームの体験、おえかき、スイッチでのゲーム対戦</Text>
            </Box>

            
            
        </OfficialEventLayoutFrame>
    )
}
export default KodomoKikaku