import * as React from "react"
import { Text, Heading, Image, Center } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import MutsumeGPImage from "../../images/official_event/mutsumeGP.jpg"

const MutsumeGP = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>むつめ祭グランプリ</Heading>
            <Text>お気に入りのパフォーマンスや装飾を見つけたら、是非投票してください！
                投票は配布されるQRコード(パフォーマンス部門)、または出店団体の区画内に掲示されているQRコード(装飾部門)からお願いします！
                ★表彰式は11/27 19:20〜 @メインステージで行います★</Text>
            <Center>
                <Image src={MutsumeGPImage} width="90%"/>
            </Center>
            
        </OfficialEventLayoutFrame>
    )
}
export default MutsumeGP