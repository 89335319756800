import * as React from "react"
import { Text, Heading, Image, Center } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import bus_times from "../../images/bus_times.png"

const BusTime = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>バスの時刻表</Heading>
            <Center>
                <Image src={bus_times}/>
            </Center>
            
        </OfficialEventLayoutFrame>
    )
}
export default BusTime