import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import TalkShowImage from "../../images/official_event/talk_show.jpg"

const TalkShow = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>岡本信彦 トークイベント in SAITAMA Univ.</Heading>
            <Text>
                第73回むつめ祭のトークショーゲストは、声優として幅広く活躍されている岡本信彦さんです！<br />
                <br />
                【日時】11月26日(土)<br />
                17:00 開場　18:00開演<br />
                <br />
                【会場】埼玉大学　全学講義棟1-301<br />
                <br />
                【チケット】2000円(全席指定、事前販売)<br />
                <br />
            </Text>
            <a href="https://twitter.com/Mutsume_talk?s=20&t=XEI6rIFhl_Ul8N-vAK6sFQ">
                <Text as="u">詳細はトークショーTwitterまで（@Mutsume_talk）</Text>
            </a>
            <Image src={TalkShowImage} />
        </OfficialEventLayoutFrame>
    )
}
export default TalkShow