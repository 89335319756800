import * as React from "react"
import { Text, Heading, Image, VStack } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"
import LayoutFrame from "../../components/layout_frame"

const Questions = () => {
    return (
        <LayoutFrame>
            <Heading margin={5}>よくある質問</Heading>


            <VStack align="left">
                <Heading as="h2" fontSize={20}>◇⼊場について</Heading>
                <Text>
                    Q. ⼊場チケットは無料ですか？<br />
                    A. はい，無料です。
                    <a href="https://passmarket.yahoo.co.jp/event/show/detail/02nyuasr7wm21.html">
                        <Text as="u">無料電子チケット</Text>
                    </a>
                    よりお申し込みいただけます。ログインせずにお申し込みいただけます。<br />
                    <br />
                    Q. ⼀般⼈でも⼊場できますか？<br />
                    A. はい。第 73 回むつめ祭は，⼀般の⽅も制限なくご来場いただけます。<br />
                    <br />
                    Q. グループの場合，⼊場チケットは代表者のみ申し込めば良いですか？<br />
                    A. ⼊場される⽅全員が，1 ⼈ 1 ⼈ご本⼈でお申し込みください。ただし，スマホをお持ちでない⽅などの分として，同じ端末で，複数⼈分を 1 ⼈ずつ申し込みいただくことは問題ありません。<br />
                    <br />
                    Q. ⼊場チケットを誤って申し込んでしまいました。<br />
                    A. キャンセルの必要はございませんので，そのままで⼤丈夫です。<br />
                    <br />
                    Q. ⼊場チケットの申し込みはしたのですが，むつめ祭に⾏けなくなってしまいました。キャンセルはどのように⾏えば良いでしょうか。<br />
                    A. キャンセルの必要はございません。来年のむつめ祭でお待ちしております。<br />
                    <br />
                    Q. ⼊場チケットは，当⽇申し込みできないですか？<br />
                    A. ⼊場チケットの申し込みは，最終⽇の閉場時間まで受け付けております。しかしながら，当⽇は混雑が予想されますので，事前に⼊⼿なさってからのご来場にご協⼒お願いいたします。<br />
                    <br />
                    Q. ⼊場チケットは，電⼦チケットのみですか？<br />
                    A. スマホをお持ちでない⽅⽤として，紙チケットの当⽇受付も⾏います。スマホをお持ちの⽅は，電⼦チケットでのご来場をお願いいたします。<br />
                    <br />
                    Q. 現役の埼⽟⼤学⽣，埼⽟⼤学院⽣も，⼊場チケットは必要ですか？<br />
                    A. はい，必要です。教職員の⽅々も，できる限り⼊場チケットの⼊⼿にご協⼒お願いいたします。<br />
                    <br />
                    Q. どこから⼊場できますか？<br />
                    A. ⼊場⼝は，正⾨の「正⾯⼊場⼝」と，正⾨側の駐輪場の「駐輪場⼊場⼝」の 2 ヶ所となり，これ以外からは，⼊場できません。（関係者を除く）<br />
                    <br />
                    Q. なぜ，無料⼊場チケットが必要なのですか？<br />
                    A. 感染症対策のために実施しております。有事の際，むつめ祭にご来場された⽅にすぐに連絡が取れるようにするためです。偽名・偽アドレスでのお申し込みは絶対におやめください。<br />
                    <br />
                    Q. お酒は持ち込めますか？<br />
                    A. お酒の持ち込みは厳禁です。また，酔った状態の⽅のご⼊場をお断りする場合がありますので，あらかじめご了承ください。<br />
                    <br />
                    Q. ペットは⼊場できますか？<br />
                    A. 恐れ⼊りますが，⼊場できません。<br />
                    <br />
                    Q. トークショーのチケット，ホームカミングデーのチケットでは⼊場できませんか？<br />
                    A. できます。トークショーおよびホームカミングデーのチケットをお持ちの⽅は，無料⼊場チケットを⼊⼿いただく必要はございません。これらのチケットのご準備をお願いいたします。<br />
                    <br />
                    Q. 再⼊場できますか？<br />
                    はい。しかしながら，再入場の場合でも必ず入場ゲートをお通りください。<br />
                </Text>

                <Heading as="h2" fontSize={20} paddingTop={5}>◇来場⽅法について</Heading>
                <Text>
                    Q. 埼⽟⼤学までのアクセスについて教えてください。<br />
                    A.
                    <a href="http://www.saitama-u.ac.jp/access/accessmap/">
                        <Text as="u">埼玉大学アクセスマップ</Text>
                    </a>をご覧ください。JR 南与野駅，JR 北浦和駅，東武志⽊駅，JR 北朝霞駅からバスが出ています。<br />
                    <br />
                    Q. 駐輪場，駐⾞場はありますか？<br />
                    A. 駐輪場はございますので⾃転⾞でのご来場は可能です。駐⾞場はございませんので，お⾞でのご来場は絶対におやめください。<br />
                </Text>

                <Heading as="h2" fontSize={20} paddingTop={5}>◇会場について</Heading>
                <Text>
                    Q. 飲⾷販売はありますか？<br />
                    A. ございます。多数の出店がございますので，お楽しみください。<br />
                    <br />
                    Q. 当日の出店は，キャッシュレス決済に対応していますか？<br />
                    A.   大変申し訳ございませんが，原則現金でのお支払いのみとなります。<br />
                    <br />
                    Q.　 授乳室や屋内の休憩場所はありますか？<br />
                    A.   ございます。場所が分からない場合は，黄緑色のブルゾンを着た委員にお尋ねください。<br />
                    <br />
                    Q.　 会場内でのベビーカーや車椅子などの使用は可能ですか？<br />
                    A.   可能です。<br />
                    <br />
                    Q. 障がい者⽤トイレはございますか？<br />
                    A. ございます。場所は，当⽇配布のガイドマップをご覧ください。<br />
                    <br />
                    Q. アルコール類の販売はありますか？<br />
                    A. 第 73 回では，アルコール類の販売は⼀切ございません。<br />
                    <br />
                    Q.　 会場内は禁煙ですか？<br />
                    A.   禁煙ですが，喫煙所が数カ所ございます。<br />
                </Text>

                <Heading as="h2" fontSize={20} paddingTop={5}>◇イベントについて</Heading>
                <Text>
                    Q. 芸能⼈は来ますか？<br />
                    A. はい。11 ⽉ 26 ⽇（開催 2 ⽇⽬）に声優の岡本信彦さんのトークショー「岡本信彦 トークイベント in SAITAMA Univ.」が，11 ⽉ 27 ⽇（開催 3 ⽇⽬）にさらば⻘春の光とひよしなかよしによるお笑いライブがございます。<br />
                    <br />
                    Q. 芸能イベントは有料ですか？<br />
                    A. トークショーは有料です。チケット販売はすでに終了しております。お笑いライブは，無料ですが，当⽇に整理券配布を⾏います。<br />
                    <br />
                    Q.　 お笑いライブの整理券配布はどのように行われますか？<br />
                    A.   お笑いライブ当日（11/27）午前10時より全学講義棟1号館前にて、配布を行います。１名様につき2名まで先着での配布となります。<br />
                    <br />
                    Q. どのような本部企画がありますか？<br />
                    A. 公式サイト「
                    <a href="/official_event_guide">
                        <Text as="u">本部企画</Text>
                    </a>
                    」をご覧ください。<br />
                </Text>

                <Heading as="h2" fontSize={20} paddingTop={5}>◇その他</Heading>
                <Text>
                    Q. ⾬天時は中⽌ですか？<br />
                    A. むつめ祭は，原則⾬天でも⾏われます。しかしながら，フリーマーケットやイルミネーションなど⼀部のイベントは中⽌です。<br />
                    <br />
                    Q. 係員は⾒分けがつきますか？<br />
                    A. むつめ祭の運営を⾏っております，むつめ祭常任委員会の委員は，全員⻩緑⾊のブルゾンを着ておりますので，いつでもお尋ねください。<br />
                </Text>
            </VStack>



        </LayoutFrame>
    )
}
export default Questions