import * as React from "react"
import { Text, Heading, Image } from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import geinou from "../../images/official_event/geinou.jpg"

const Geinou = () => {
    return (
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>むつめ祭お笑いライブ</Heading>
            <Text>今年は３年ぶりのお笑いライブ開催決定！！<br /><br />
                　皆様に笑顔を届けてくれるべく　さらば青春の光　ひよしなかよし　がスペシャルゲストとして登場。埼大生はもちろん遊びにきてくださる方々のむつめ祭の最高の思い出になることは間違い無いでしょう！皆様のご来場をお待ちしております。<br/><br/>
                場所　　　全学講義棟1号館301教室<br />
                日程　　　2022年11月27日 <br />
                時間　　　開場11:30　開演12:00 <br />
                チケット　無料（整理券配布有　11/27 10:00-全学講義棟1号館前にて配布予定　先着順） <br /><br/>
                ※会場に入る際に、検温・アルコール消毒をお願いしております。またマスクの着用を必ず行ってください。 <br />
                ※体調のすぐれない方の入場をお断りする場合がございます。 <br />
                ※都合により内容が一部変更される変更される場合がございます。 <br />
                ※ライブ中の撮影・録音録画はお断りさせていただきます。</Text>

            <Image paddingTop={5} margin="0 auto" width="90%" src={geinou} />
        </OfficialEventLayoutFrame>
    )
}
export default Geinou