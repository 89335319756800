

import { getAllEvents, filterEventsByString, filterEventsByType, filterEventsByPlace } from "../data/event_selector"


import { Checkbox, Heading, Text, Input, InputGroup, Radio, RadioGroup, Stack, useCheckbox, HStack, InputLeftAddon, Box, Center, VStack, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Spacer } from "@chakra-ui/react"
import React, { useState } from "react"
import LayoutFrame from "../components/layout_frame"
import ShowMap from "../components/show_map"
import { Event } from "../data/event_types"
import { EventList } from "../components/event_list"

import { EventType } from "../data/event_types"
import { Place } from "../data/places"
import { BellIcon, RepeatIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { accent_color, main_color } from "../components/UItheme"

const Events = () => {


    //文字列検索
    const [serch_string, setSerchString] = React.useState('')
    const onChange = (input: React.ChangeEvent<HTMLInputElement>) => {
        setSerchString(input.target.value)
    }

    //種類絞り込み
    const type_list = EventType
    const [filter_type, setTypeFilter] = React.useState("全て")

    //場所絞り込み
    const place_list = Place
    const [filter_place, setPlaceFilter] = React.useState("全て")


    //検索手続き
    let events: Event[] = getAllEvents()
    if (serch_string != '') {
        events = filterEventsByString(events, serch_string)
    }
    if (filter_type != "全て") {
        events = filterEventsByType(events, filter_type as EventType)
    }
    if (filter_place != "全て") {
        events = filterEventsByPlace(events, filter_place as Place)
    }


    const ShowSearchResult = () => {
        if (events.length == 0) {
            return (
                <Box borderColor={accent_color} borderWidth="2px" borderRadius="lg">
                    <Center>
                        <VStack>
                            <RepeatIcon width={100} height={100} color={main_color} />
                            <Heading>指定された条件のイベントはありません</Heading>
                            <Heading>条件を変えてお試しください</Heading>
                        </VStack>

                    </Center>

                </Box>
            )
        } else {
            return (<EventList events={events} />)
        }
    }
    /*
    
    */

    return (
        <LayoutFrame>
            <Heading>出展一覧</Heading>
            <InputGroup>
                <InputLeftAddon children="検索" />
                <Input
                    value={serch_string}
                    onChange={onChange}
                    placeholder="一単語まで" />
            </InputGroup>

            <Accordion allowMultiple>
                <AccordionItem>
                    <HStack width="100%" borderColor={accent_color} borderWidth="1px" borderRadius="lg" >
                        <AccordionButton>

                            <Text>種類と場所で絞り込み</Text>
                            <Spacer/>
                            <AccordionIcon  alignSelf="end"/>


                        </AccordionButton>
                    </HStack>
                    <AccordionPanel>
                        <HStack>
                            <RadioGroup value={filter_type} onChange={setTypeFilter}>
                                <Stack direction="column">
                                    <Text>種類で絞り込み</Text>
                                    <Radio value="全て">全て</Radio>
                                    {
                                        type_list.map(event_type => {
                                            return (
                                                <Radio value={event_type.toString()}>{event_type.toString()}</Radio>
                                            )
                                        })
                                    }
                                </Stack>
                            </RadioGroup>
                            <RadioGroup value={filter_place} onChange={setPlaceFilter}>
                                <Stack direction="column">
                                    <Text>場所で絞り込み</Text>
                                    <Radio value="全て">全て</Radio>
                                    {
                                        place_list.map(place_name => {
                                            return (
                                                <Radio value={place_name.toString()}>{place_name.toString()}</Radio>
                                            )
                                        })
                                    }
                                </Stack>
                            </RadioGroup>
                        </HStack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>



            {
                ShowSearchResult()
            }



        </LayoutFrame>
    )
}
export default Events