import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import LayoutFrame from "../layout_frame";

type LayoutProps = {
    children: ReactNode
}
const OfficialEventLayoutFrame: React.FC<LayoutProps> = ({children}) => {
    return(
        <LayoutFrame>
            <Box marginTop={5}>{children}</Box>
        </LayoutFrame>
    )
}
export default OfficialEventLayoutFrame;