import { Link, VStack, Text, Heading, Center, Image } from "@chakra-ui/react";
import React from "react";

import MutsumeGPImage from "../../images/official_event/mutsumeGP.jpg"

const OfficialEventList = () => {
    return (
        <VStack >
            <Text paddingBottom={10}>本部企画はむつめ祭常任委員会が直接運営する企画です</Text>
            {

                //本部企画には番号がつく
            }
            <OfficialEventLink title="1. オープニングイベント" link="opening" />
            <OfficialEventLink title="2. むつめ祭イルミネーションプロジェクト2022" link="illumination" />
            <OfficialEventLink title="3. 福引き" link="fukubiki" />
            <OfficialEventLink title="4. むつめ祭スタンプラリー" link="stamp_rally" />
            <OfficialEventLink title="5. こどもきかく＆こどもラリー" link="kodomo_kikaku" />
            <OfficialEventLink title="6. フリーマーケット" link="frea_market" />
            <OfficialEventLink title="7. なぞとき" link="nazotoki" />
            <OfficialEventLink title="8. むつめキャンパス" link="mutsume_campus" />
            <OfficialEventLink title="9. 岡本信彦 トークイベント in SAITAMA Univ." link="talk_show" />
            <OfficialEventLink title="10. むつめ祭お笑いライブ" link="geinou" />
            <OfficialEventLink title="11. ビンゴ大会" link="bingo" />
            <OfficialEventLink title="12. 埼大生抽選" link="cyuusen" />
            <OfficialEventLink title="13. エンディングイベント" link="ending" />
        </VStack>
    )
}
export default OfficialEventList;

const OfficialEventLink = ({ title, link }: { title: string, link: string }) => {
    return (
        <Link href={"/official/" + link}>
            <Text>{title}</Text>
        </Link>
    )
}