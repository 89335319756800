import * as React from "react"
import { Text, Heading, Image} from "@chakra-ui/react"
import OfficialEventLayoutFrame from "../../components/official/official_event_frame"

import fukubiki from "../../images/official_event/fukubiki.jpg"

const Fukubiki = () => {
    return(
        <OfficialEventLayoutFrame>
            <Heading marginBottom={5}>福引き</Heading>
            <Text>　今年のむつめ祭でも豪華景品が当たる福引きを行います。<br />
            　パンフレットに乗っているアンケートに答えていただくと、引くことができます。外れはなしとなっていますので、ぜひお越しください。<br />
            <br />
            開催日：全日開催<br />
            開催時間：1日目13:00~19:00<br />
            　　　　　2日目11:00~19:00<br />
            　　　　　3日目11:00~19:00<br />
            開催場所：教育学部A棟
            </Text>

            <Image margin="0 auto" width="90%" src={fukubiki} />
        </OfficialEventLayoutFrame>
    )
}
export default Fukubiki